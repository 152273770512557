/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import PageLoader from '@components/common/PageLoader';
import OnRouteChange from '@containers/common/OnRouteChange';
import { APP_CONFIG } from '@constants';
import Welcome from '@components/Welcome/Welcome';

import { connect } from 'react-redux';
import Music from '@components/Music';
import Video from '@components/Video';
import { bindActionCreators } from 'redux';
import Profile from './containers/Profile';
import Feedback from './components/Feedback';
import MusicPlayer from './components/MusicPlayer/MusicPlayer';
import { EN } from '@localization';
import { getTrendingMediaList } from './reducers/trending';
import { getArtistInfo } from './reducers/profile';
import { updateIsUserLoggedInStatus } from './reducers/oauth';
import { getPaymentServiceOffers, getAcrValue, updateServiceOffers } from './reducers/payment';
import { MusicDetailPage } from './components/MusicDetailPage/MusicDetailPage';
import './assets/fonts/SegoeUI/SegoeUI.ttf';
import TermsAndCondition from './components/TermsAndCondition';
import AdsConfirm from './components/Ads/AdsConfirm';
import AdsAccess from './components/Ads/AdsAccess';
import AdsEnter from './components/Ads/AdsEnter';
import { makeStyles } from '@material-ui/core';
import { checkViewPort } from './helpers/utils';
import AicUnsubscribe from './components/AicUnsubscribe';
import AicSubscribe from './components/AicSubscribe';



const useStyles = makeStyles((theme) => ({
  root: {
    '--received': window.sessionStorage.getItem('useStyles'),
  }
}));

const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return <div>Sorry, unable to load the page</div>;
  }
  return null;
};

const Login = Loadable({
  loader: () => import('@components/LoginForm'),
  loading: LoadingComponent,
});

const Landing = Loadable({
  loader: () => import('@components/Landing'),
  loading: LoadingComponent,
});

const ForgotPassword = Loadable({
  loader: () => import('@components/ForgotPassword'),
  loading: LoadingComponent,
});

const notFound = Loadable({
  loader: () => import('@components/404NotFound'),
  loading: LoadingComponent,
});

const Dashboard = Loadable({
  loader: () => import('@components/Dashboard'),
  loading: LoadingComponent,
});

const NavBar = Loadable({
  loader: () => import('@components/BottomNavbar'),
  loading: LoadingComponent,
});

const Sidebar = Loadable({
  loader: () => import('@components/SidebarMenu'),
  loading: LoadingComponent,
});

const SocialMedia = Loadable({
  loader: () => import('@components/SocialMedia'),
  loading: LoadingComponent,
});

// const Notification = Loadable({
//   loader: () => import('@components/Notification'),
//   loading: LoadingComponent,
// });

const Routes = (props) => {
  const [url, setCurrentUrl] = useState(window.location.pathname);
  const firstTimeRender = useRef(true);
  const rootStyles = useStyles();

  useEffect(() => {
    callUtag();
    if (window.location.pathname.includes('/Artist/')) {
      window.location.replace('/');
    }
  }, [url]);


  const callUtag = () => {
    const userSurrogateId = window.localStorage.getItem('userDetails');
    window?.utag?.view({
      "tealium_event": "view",
      "event_name": "page_view", //  login or registration or subscription on other page pass "page_view"
      "page_name": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}${window.location.pathname.replaceAll("/", ":")}`,
      "page_section": `${window.location.host.replace(/com|co.za/g, '').split('.').join("")}:${window.location.pathname.split('/').pop()}`, //the last path should be passed here eg :  login or home or course;
      "page_parent_domain": `${window.location.origin}`,   // need to pass the main domain
      "page_country": "south africa",// eg - south africa
      "page_url": `${window.location.href}`,
      "page_locale": "za",
      "page_title": `${document.querySelector("meta[property='og:title']").getAttribute('content')}`,
      "page_platform": checkViewPort(),
      "visitor_id_asset_active": userSurrogateId ? userSurrogateId : '',    //user id
      "visitor_login_status": userSurrogateId ? "loggedin" : "loggedout",
      "page_load": 1,
      "site_version": "1.0",
      "site_type": "web",
    })
  }

  useEffect(() => {
    props.updateIsUserLoggedInStatus();
    console.log('isUserLoggedIn ====>', props.isUserLoggedIn);
  }, [url]);

  useEffect(() => {
    let host = "";
    if (window.location.hostname === "localhost") {
      host = 'artist.sakhaglobal.com';
    } else {
      host = window.location.hostname;
    }
    props.getArtistInfo(host).then((data) => {
      window.sessionStorage.setItem('onmoPayConfig', JSON.stringify(data.onmoPayConfig));
    });
  }, [])

  const handleConnectionChange = () => {
    let webPing = '';
    if (navigator.onLine) {
      webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              if (!window.sessionStorage.getItem('he_acr_value') && !window.sessionStorage.getItem('is_he_user')) {
                checkisHeUser();
              }
            }).catch((e) => {
              console.log("error =>", e.message);
              if (e.message === 'Failed to fetch') {
                window.sessionStorage.removeItem('is_he_user');
                window.sessionStorage.removeItem('he_acr_value');
              }
            });
        }, 2000);
    } else {
      window.clearInterval(webPing);
      window.sessionStorage.removeItem('is_he_user');
      window.sessionStorage.removeItem('he_acr_value');
    }
  }

  const checkisHeUser = () => {
    const params = new URLSearchParams(window.location.search);
    const cookieObj = new URLSearchParams(document.cookie.replaceAll("&", "%26").replaceAll("; ", "&"))

    if (params.has('acrValue') || params.has('artistDetails')) {
      const acrValue = params.get('acrValue');
      if (acrValue && acrValue !== "undefined") {
        window.sessionStorage.setItem('is_he_user', true);
        window.sessionStorage.setItem('he_acr_value', acrValue);
      } else if (cookieObj.get("he_acr")) {
        window.sessionStorage.setItem('is_he_user', true);
        window.sessionStorage.setItem('he_acr_value', cookieObj.get("he_acr"));
      } else {
        window.sessionStorage.setItem('is_he_user', false);
      }

      const artistDetails = decodeURIComponent(params.get('artistDetails').replace(/`/g, '\''));
      if (artistDetails && artistDetails !== "undefined") {
        window.sessionStorage.setItem('artistDetails', artistDetails);
      }
      let acrvalueString = `acrValue=${acrValue}`;
      let newpath = window.location.href.replace(`?${acrvalueString}`, '');
      newpath = newpath.replace(`&${acrvalueString}`, '');

      const encodedartistDetails = encodeURIComponent(artistDetails.replace(/\'/g, '`'));
      let artistDetailsString = `artistDetails=${encodedartistDetails}`;
      newpath = newpath.replace(`&${artistDetailsString}`, '');
      window.location.href = newpath;
    } else if ((!window.sessionStorage.getItem('he_acr_value') && !window.sessionStorage.getItem('is_he_user')) ||
      !window.sessionStorage.getItem('artistDetails')) {
      let currentPath = window.location.href;
      currentPath = encodeURIComponent(currentPath);
      if (window.location.hostname === 'localhost') {
        window.location.href = `http://${window.location.hostname}:5000/findacr?redirectUrl=${currentPath}`;
      } else {
        window.location.href = `https://${window.location.hostname}/findacr?redirectUrl=${currentPath}`;
      }
    }
    // non-He user
    let channel = '';
    if (url === '/DMLandingPage' || url === '/DMConfirmPage' || url === '/DMThankPage') {
      channel = EN.DIGITAL_MARKETING;
    }
    const serviceUrl = window.location.href;
    if (props.isUserLoggedIn && window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
      const mobNm = window.localStorage.getItem('phone');
      props.getPaymentServiceOffers(window.localStorage.getItem('acrValue'), channel, serviceUrl);
    } else if (window.sessionStorage.getItem('is_he_user') === 'true' && window.sessionStorage.getItem('he_acr_value')) {
      // HE user
      props.getPaymentServiceOffers(encodeURIComponent(window.sessionStorage.getItem('he_acr_value')), channel, serviceUrl);
    } else {
      props.updateServiceOffers();
    }
  }

  useEffect(() => {
    if (window.location.hostname === 'celebrityconnect.co.za') {
      let path = window.location.href;
      path = path.replace('celebrityconnect.co.za', 'drtumi.celebrityconnect.co.za');
      if (window.location.protocol === 'http') {
        path = path.replace('http', 'https');
      }
      window.location.href = path;
    }
    if (window.location.protocol === 'https:') {
      if (firstTimeRender.current) {
        firstTimeRender.current = false;
        handleConnectionChange();
        //if network diconnects
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);
      }
      checkisHeUser();
      return () => {
        window.removeEventListener('online', handleConnectionChange);
        window.removeEventListener('offline', handleConnectionChange);
      }
    }
    else if (!window.location.pathname.includes('/findacr')) {
      let path = window.location.href;
      path = path.replace('http', 'https');
      window.location.href = path;
    }
  }, [url, props.isUserLoggedIn]);

  return (
    <Router>
      <OnRouteChange
        currentUrl={(val) => setCurrentUrl(val)}
      // windowWidth={(val) => setWindowWidth(val)}
      >
        <div id="dashboardContainer" className="container-fluid p-0 blackBg">
          {url !== '/' && url !== '/findacr' && url !== '/admin/login' && url !== '/DMConfirmPage' && url !== '/DMLandingPage' && url !== '/DMThankPage' && props.windowWidth >= 1024 && <Sidebar windowWidth={props.windowWidth} trendingVideos={props.trendingVideoList} isUserLoggedIn={props.isUserLoggedIn} />}
          <Switch>
            <Route exact path={`${APP_CONFIG.BASE_URL}/`} component={Landing} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/findacr`} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/DMConfirmPage`} component={AdsConfirm} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/DMLandingPage`} component={AdsAccess} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/DMThankPage`} component={AdsEnter} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/AicUnsubscribe`} component={AicUnsubscribe} />
            <Route exact path={`${APP_CONFIG.BASE_URL}/AicSubscribe`} component={AicSubscribe} />
            {url !== '/' && props.windowWidth >= 1024 && (
              <>
                <Route exact path={`${APP_CONFIG.BASE_URL}/admin/login`} component={Login} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/welcome`} component={Welcome} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/forgotpassword`} component={ForgotPassword} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicplayer`} component={MusicPlayer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicLibrary`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoLibrary`} />
                <Route path={`${APP_CONFIG.BASE_URL}/musicPlaylist`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoPlaylist`} />
                <Route path={`${APP_CONFIG.BASE_URL}/musicPlaylistListing`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoPlaylistListing`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicFavourite`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/videoFavourite`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/likedVideos`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicAlbum`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicAlbumListing`} />
                {/* <Route exact path={`${APP_CONFIG.BASE_URL}/socialmedia`} component={SocialMedia} /> */}
                <Route exact path={`${APP_CONFIG.BASE_URL}/postdetails`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/facebook`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/twitter`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/profile`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/mostViewedMusic`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/recentlyPlayed`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/latestMusic`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/musicByGenre`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/musicByAlbum`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/albumList`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/profile/feedback`} component={Feedback} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicdetail/:musicId`} component={MusicDetailPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/celebrityadmin/musicdetail/:musicId`} component={MusicDetailPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/productadmin/musicdetail/:musicId`} component={MusicDetailPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/videoplayer/:videoId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/post/detail/:postId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/search/:searchKey`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/notification`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/liveplayer/:videoId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/competition/participants/:competitionId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/competition/winners/:competitionId`} />
              </>
            )}
            {window.location.pathname !== '/profile' && window.location.pathname !== '/termsAndCondition' && window.location.pathname !== '/admin/login' && window.location.pathname !== '/welcome' && props.windowWidth < 1024 && <NavBar />}
            {url !== '/' && props.windowWidth < 1024 && (
              <>
                <Route exact path={`${APP_CONFIG.BASE_URL}/admin/login`} component={Login} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/welcome`} component={Welcome} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/homepage`} component={Dashboard} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicplayer`} component={MusicPlayer} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicdetail/:musicId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/celebrityadmin/musicdetail/:musicId`} component={MusicDetailPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/productadmin/musicdetail/:musicId`} component={MusicDetailPage} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/profile`} component={Profile} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/termsAndCondition`} component={TermsAndCondition} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/video`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music`} component={Music} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/socialmedia`} component={SocialMedia} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/profile/feedback`} component={Feedback} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/videoplayer`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/post/detail/:postId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicLibrary`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoLibrary`} />
                <Route path={`${APP_CONFIG.BASE_URL}/musicPlaylist`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoPlaylist`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicPlaylistListing`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/videoPlaylistListing`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicFavourite`} />
                <Route path={`${APP_CONFIG.BASE_URL}/videoFavourite`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/likedVideos`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicAlbum`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/musicAlbumListing`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/postdetails`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/facebook`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/twitter`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/mostViewedMusic`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/recentlyPlayed`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/latestMusic`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/musicByGenre`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/musicByAlbum`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/music/albumList`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/search/:searchKey`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/notification`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/competition`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/liveplayer/:videoId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/competition/participants/:competitionId`} />
                <Route exact path={`${APP_CONFIG.BASE_URL}/competition/winners/:competitionId`} />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/facebook`}
                // component={Fbwidgets}
                />
                <Route
                  exact
                  path={`${APP_CONFIG.BASE_URL}/twitter`}
                // component={Twitterwidgets}
                />
              </>
            )}
            <Route path="*" component={notFound} />
          </Switch>
        </div>
        {/* {url !== '/' && windowWidth < 1024
          && <NavBar />} */}
      </OnRouteChange>
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getTrendingMediaList,
  updateIsUserLoggedInStatus,
  getPaymentServiceOffers,
  updateServiceOffers,
  getArtistInfo,
  getAcrValue
}, dispatch);

const mapStateToProps = (state) => {
  return {
    windowWidth: state.ui.windowWidth,
    trendingVideoList: state.trending.trendingVideoList,
    isUserLoggedIn: state.oauth.isUserLoggedIn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
